
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParadigmInspection',
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(currentStore, {
      paradigmContent: 'paradigmContent',
      samplesCount: ({ samples }) => samples.standard.length,
    }),
  },
  components: { PaneTitle },
})
